import React from 'react'
import logo from "../logo.png";
import homeImage from "../home.png";
import chatImg from "../chart.jpeg";

import { Link } from 'react-router-dom';
import { FaBeer, FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";

function Home() {
    return (
        <div className='home-body'>
            <h3>TOKENOMICS MODEL</h3>
            <div className='text-area'>
                <p>
                    Developing a robust tokenomics model is essential for the successful launch of a token associated
                    with Cunningham Mining. Tokenomics refers to the economic model governing the issuance,
                    distribution, and utilization of tokens within a specific ecosystem. Given Cunningham Mining's
                    context in the mineral resources and energy sector, here's a tokenomics plan for the project.
                    <br /><br />
                    <b style={{ fontSize: '16px', fontWeight: 'bold' }}> Tokenomics:
                    Those who purchase the Token, earn a right to buy Gold from production at production rate in future. Meaning they hold the token and have a reserve price of spot gold. In future if price of gold rises, stakeholder can buy the gold at their reserve price from production
                    </b>
                    <br /><br />
                    <span style={{ color: '#00b8ff', fontWeight: 'bold', fontSize: '16px' }}>
                        Formula: (Gold product cost) - (Market price) = Profit margin</span>
                </p>
                {/* <div>
                        <img src={homeImage} style={{ width: '90%' }} />
                    </div> */}
                <div>
              

                    <table className='table table-bordered custom-tbl'>
                        <tr>
                            <td>Token Name:</td>
                            <td>Nugget Trap Gold Token (NGT Tokens)</td>
                        </tr>
                        <tr>
                            <td>Token Standard</td>
                            <td>ERC-20 (or suitable standard for your blockchain platform)</td>
                        </tr>
                        <tr>
                            <td>Circulating Supply:</td>
                            <td>1 Billion   </td>
                        </tr>
                        <tr>
                            <td>Treasury:</td>
                            <td>4 Billion   </td>
                        </tr>
                        <tr>
                            <td>Issuance for the offering</td>
                            <td>100,000,000 NGT Token (One Hundred Million NGT)</td>
                        </tr>
                        <tr>
                            <td>Price of each Digital Token Unit</td>
                            <td>$0.60 USD</td>
                        </tr>
                        <tr>
                            <td>Registration Platform for users:</td>
                            <td>OriginatorX Issuing Platform</td>
                        </tr>
                        <tr>
                            <td>Wallets:</td>
                            <td>Metamask Wallet, Smartpay Wallet</td>
                        </tr>
                        <tr>
                            <td>Acceptable forms of Payments:</td>
                            <td>Wire, ETH, BTC</td>
                        </tr>
                    </table>
                </div>
                <p>
                    Tokenomics 2: <br /><br />
                    The value of the token may have multiple value markers that investors will identify with. It
                    represents the gold value in discovery and it represents distribution of funds from Gold Extraction
                    to Gold Sales. <br /><br />
                    The company may trigger a listing on multiple crypto exchanges on accomplishing the target of
                    $60Million raise.
                </p>
                <p>Allocation of Tokens:</p>
                <div className='text-center'>
                    <img src={chatImg} className='image-responsive' style={{width:'100%'}} />
                    <br />
                    <br />
                </div>
                <table className='table-bordered custom-tbl' style={{width:'100%'}}>
                    <tr>
                        <td>Reservation for Compensations of Supply</td>
                        <td>5%</td>
                    </tr>
                    <tr>
                        <td>Breakdown of Distribution of the Supply</td>
                        <td>% Breakdown</td>
                    </tr>
                    <tr>
                        <td>Staff</td>
                        <td>30%</td>
                    </tr>
                    <tr>
                        <td>Marketing</td>
                        <td>10%</td>
                    </tr>
                    <tr>
                        <td>Advisors</td>
                        <td>30%</td>
                    </tr>
                    <tr>
                        <td>Possible Claim Acquisition</td>
                        <td>30%</td>
                    </tr>
                </table><br /><br />

                <p>
                    Holding Period: Each will have a 6 month holding period.<br /><br />

                    Token Sale: 100 Million NGT Token<br /><br />
                    Use: Use: Funding mining operations, infrastructure development, equipment purchase, 
                    and operational expenses.<br /><br />
                    Price: Determined during the token sale event, considering market conditions and project needs.
                    <br /><br />


                    Reserve Fund: 4 Billion Tokens <br /><br />

                    Use: Reserve Treasury for future opportunities to acquire Gold Claims, Proven Reserves, Production Mines,
                     Gold supply, future development, and ecosystem growth.<br /><br />
                    
                     Team and Advisors:<br /><br />
                    Vesting Period: 1-year vesting period. 15% becomes available after 4 months with a 1-year cliff 
                    on the remaining 85% to align the team's interests with long-term project success.<br /><br />

                    Community Development: TBD<br /><br />
                    Use: Marketing, community engagement, partnerships, and ecosystem expansion. <br /><br />

                    Bounty and Airdrop:<br /><br />
                    Use: Rewarding early supporters, community engagement campaigns, and awareness initiatives.
                    The Chief Permitting Officer hereby issues this permit subject to the following conditions that the permittee must comply with
                    <br /><br />
                    
                     </p>
                     <h3>ApprovedActivities:</h3>
                     <ul>
                        <li>Work Related Structures: 6 structures. (0.03 ha) </li>
                        <li>Mining Areas: 3 areas, 25m W * 100m L (0.75 ha) </li>
                        <li>Processing infrastructure: Coarse Tailings stockpiles, Trommel and Highbanker (0.015 ha) </li>
                        <li>Test pits: 20 sites, 1.5m L * 1m W * 1.5m D each (0.01ha) </li>
                        <li>New Access Construction: 1500m * 3m W (0.45ha) </li>
                        <li>1. ExistingAccessModification:1000mL*3mW(0.3ha) </li>
                        <li>Other: Storage of up to 1600L of fuel in barrels. </li>
                        <li>For a total disturbance area of 1.56ha </li>
                     </ul>
                     <h3> Activities not approved</h3>
                     <ul>
                        <li>Fording of watercourses is not authorized. </li>
                        <li>Suction Dredging and/or Enhanced Sniping are not authorized. </li>
                        <li>Individual settling ponds must not be constructed greater than 2.5 m above
                             grade or be capable of impounding greater than 30,000m3 volume.</li>
                     </ul>


                {/* <div>
                    <br />
                    <h5>  TOKEN UTILITY:</h5>
                    <p>
                        <b>- Ownership and Governance:</b> NGT Token holders have voting rights in the project's decision-
                        making processes, including budget allocation, mining strategies, and future developments.
                        <br /><br />
                        <b> - Rewards:</b> Token holders receive a share of mining profits, distributed in NGT Token, providing a
                        passive income stream.<br /><br />
                        <b>- Exclusive Access:</b> Holders may have exclusive access to mining reports, industry insights, and
                        events related to the Golden Triangle mining operations.<br />
                    </p>
                    <p>
                        <h5> MINING PROFIT DISTRIBUTION:</h5>
                        - 70%: Distributed among token holders as dividends in proportion to their holdings.<br /><br />
                        - 20%: Reinvested in mining operations, ensuring sustainable growth.<br /><br />
                        - 10%: Allocated to the Reserve Fund for future project developments and expansion.<br /><br />
                    </p>
                </div> */}

                {/* <div>
                    <h5>
                        ROADMAP AND MILESTONES:</h5>
                    <b>  Phase 1:</b> Exploration and Setup
                    - Develop infrastructure, conduct geological surveys, and initial exploration of the Nugget
                    Trap property.<br /><br />
                    <b>Phase 2:</b> Mining Commencement
                    - Begin active mining operations, invest in advanced equipment, and optimize production
                    processes.<br /><br />
                    <b>Phase 3:</b> Expansion and Sustainability
                    - Expand mining operations to optimize resource extraction, enhance environmental
                    sustainability, and increase profitability.<br /><br />
                    <b>Phase 4:</b> Ecosystem Development
                    - Introduce partnerships, enhance community engagement, and explore collaborations with
                    other projects in the industry.<br /><br />

                    <h5>LEGAL AND COMPLIANCE:</h5>
                    - Comply with relevant securities regulations and ensure transparent communication with
                    regulatory authorities.<br /><br />
                    - Implement robust KYC/AML procedures for all token holders to maintain compliance with
                    global regulations.
                </div> */}
                <br />
                <div>
                    <h5>DISCLAIMER:</h5>
                    <p className='text-15'>The Nugget Trap Gold Tokenomics model is subject to change based on market conditions,
                        regulatory requirements, and project needs. Token holders are encouraged to stay updated with
                        project announcements for the latest information. <br /><br />
                        Please note that the above Tokenomics model is a general framework and should be tailored to
                        meet the specific needs and goals of Cunningham Mining.</p>
                </div>

            </div>
        </div>
    )
}

export default Home