import React, { useEffect, useState } from 'react'
import {
    Card, CardHeader, Grid2, TextField, Breadcrumbs, Typography, FormControl, Button, Divider,
    RadioGroup, FormLabel, FormControlLabel, Radio, InputLabel, Input, InputAdornment, Autocomplete,
    Backdrop,
    CircularProgress
} from '@mui/material'
import Grid from '@mui/material/Grid2';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import BasicTable from './BasicTable'
import axios from 'axios';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import Switch from '@mui/material/Switch';
import { Label } from '@mui/icons-material';
import CountrySelect from './CountrySelect';
import MuiPhoneNumber from 'material-ui-phone-number';
import { AiOutlineOrderedList } from 'react-icons/ai';
import dayjs, { Dayjs } from 'dayjs';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';


function Treasury() {
    const navigate = useNavigate();
    const location = useLocation();
    const typeOption = ['Company', 'Association', 'Other'];
    const typecurrency = ['ETH', 'BTC', 'USDT'];
    const [open, setOpen] = useState(false);
    const [newTreasury, setnewTreasury] = useState({});
    const [typeOfsetval, settypeOfsetval] = useState(null)
    const [treasuryTypeVal, settreasuryTypeVal] = useState(null);
    const [cointVal, setcointVal] = useState(null);
    const [sopen, setSOpen] = React.useState(false);
    //const typesalary = ['Monthly', 'Annual'];

    const handleClose = (event, reason
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        setSOpen(false);
    };


    const dateFormat = (ndate) => {
        const date = new Date(ndate);
        const day = String(date.getDate()).padStart(2, '0'); // Pad single digits with leading zero
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
        const formattedDate = `${day}/${month}/${year}`;
        return formattedDate;
    }

    const handalSubmit = async () => {
        if (newTreasury.treasury_type && newTreasury.total_issuance && newTreasury.tures_date) {
            setOpen(true);
            if (newTreasury.id) {
                const addTreasury = await axios.post(`${process.env.REACT_APP_API_URL}/treasury/update`, newTreasury);
            } else {
                const addTreasury = await axios.post(`${process.env.REACT_APP_API_URL}/treasury/add`, newTreasury);
            }
            setSOpen(true);
            setOpen(false);
        } else {
            alert('Please enter Treasury details!')
        }
    }
    useEffect(() => {
        if (location.state) {

            const optionToSet = typeOption.find(option => option === location.state.treasury_type); // Example: set Option 2
            setnewTreasury(location.state)
            settypeOfsetval(optionToSet)
            settreasuryTypeVal(location.state.treasury_type)
            setcointVal(location.state.currency_type);

            // treasuryTypeVal, settreasuryTypeVal
            //cointVal, setcointVal
            //(location.state)
            console.log(optionToSet)
        }
    }, [location])
    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>List</Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Button variant="contained" color='primary'
                    onClick={() => { navigate('/dashboard/treasury-list') }}
                >List
                    <AiOutlineOrderedList size={20} />
                </Button>
                <Card style={{ padding: '10px' }}>
                    <h4>Treasury </h4>
                    <form>
                        <h6>Main Contact</h6>
                        <Grid container spacing={2}>
                            <Grid size={3}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Company Name" size='small' InputLabelProps={{ shrink: true }}
                                        variant="outlined" value={newTreasury.company_name}
                                        onChange={(e) => { setnewTreasury((prev) => ({ ...prev, company_name: e.target.value })) }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid size={3}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Title" size='small' InputLabelProps={{ shrink: true }}
                                        variant="outlined" value={newTreasury.title}
                                        onChange={(e) => { setnewTreasury((prev) => ({ ...prev, title: e.target.value })) }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid size={3}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="First Name" size='small' InputLabelProps={{ shrink: true }}
                                        variant="outlined" value={newTreasury.first_name}
                                        onChange={(e) => { setnewTreasury((prev) => ({ ...prev, first_name: e.target.value })) }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid size={3}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Last Name" size='small' InputLabelProps={{ shrink: true }}
                                        variant="outlined" value={newTreasury.last_name}
                                        onChange={(e) => { setnewTreasury((prev) => ({ ...prev, last_name: e.target.value })) }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid size={3}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Email" size='small' InputLabelProps={{ shrink: true }}
                                        variant="outlined" value={newTreasury.email}
                                        onChange={(e) => { setnewTreasury((prev) => ({ ...prev, email: e.target.value })) }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid size={3}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <MuiPhoneNumber defaultCountry={'in'} label="Phone number"
                                        value={newTreasury.phone}
                                        onChange={(val) => { setnewTreasury((prev) => ({ ...prev, phone: val })) }}
                                        variant="outlined" size='small' />
                                </FormControl>
                            </Grid>
                            <Grid size={3}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <Autocomplete
                                        disablePortal className='dropdown'
                                        size='small' value={typeOfsetval}
                                        options={typeOption}
                                        getOptionLabel={(option) => option}
                                        sx={{ width: 230 }}
                                        onChange={(event, newValue) => {
                                            setnewTreasury((prev) => ({ ...prev, treasury_type: newValue }));
                                            settypeOfsetval(newValue)
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Type of Asset Class" />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid size={3}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Brief Description" size='small' InputLabelProps={{ shrink: true }}
                                        variant="outlined" value={newTreasury.brief_descrp}
                                        onChange={(e) => { setnewTreasury((prev) => ({ ...prev, brief_descrp: e.target.value })) }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid size={3}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <Autocomplete
                                        disablePortal
                                        size='small'
                                        options={typeOption}
                                        sx={{ width: 230 }} value={treasuryTypeVal}
                                        onChange={(event, newValue) => {
                                            setnewTreasury((prev) => ({ ...prev, treasury_type: newValue }));
                                            settreasuryTypeVal(newValue)
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Treasury type" />}
                                    />
                                    {/* treasuryTypeVal, settreasuryTypeVal */}
                                </FormControl>
                            </Grid>
                            <Grid size={3}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Total Issuance Treasury" size='small' InputLabelProps={{ shrink: true }}
                                        variant="outlined" value={newTreasury.total_issuance}
                                        onChange={(e) => { setnewTreasury((prev) => ({ ...prev, total_issuance: e.target.value })) }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid size={3}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <LocalizationProvider dateAdapter={AdapterDayjs} size='small'>
                                        <DatePicker size='small'
                                            label="Date" defaultValue={dayjs(newTreasury?.tures_date)}
                                            onChange={(date) => { setnewTreasury((prev) => ({ ...prev, tures_date: dateFormat(date) })) }}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                            <Grid size={3}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Description of Asset" size='small' InputLabelProps={{ shrink: true }}
                                        variant="outlined" value={newTreasury.description_asset}
                                        onChange={(e) => { setnewTreasury((prev) => ({ ...prev, description_asset: e.target.value })) }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>


                        {/* Brief Description */}


                        {/* <FormControl sx={{ m: 1, }} variant="outlined">
                            <CountrySelect />
                        </FormControl> */}
                        <Grid container spacing={2}>
                            <Grid size={3}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Initial Priced Asset" size='small' InputLabelProps={{ shrink: true }}
                                        variant="outlined" value={newTreasury.initial_priced_asset}
                                        onChange={(e) => { setnewTreasury((prev) => ({ ...prev, initial_priced_asset: e.target.value })) }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid size={3}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <Autocomplete
                                        disablePortal
                                        size='small'
                                        options={typecurrency}
                                        sx={{ width: 230 }}
                                        value={cointVal}
                                        onChange={(event, newval) => {
                                            setnewTreasury((prev) => ({ ...prev, currency_type: newval }));
                                            setcointVal(newval);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Currency type" />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid size={3}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Blockchain Type" size='small' InputLabelProps={{ shrink: true }}
                                        variant="outlined" value={newTreasury.blockchain_type}
                                        onChange={(e) => { setnewTreasury((prev) => ({ ...prev, blockchain_type: e.target.value })) }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid size={3}></Grid>
                        </Grid>
                        {/* Currency Type: Dropdown (ETH, BTC, USDT)  cointVal, setcointVal*/}



                        <hr />
                        <h5>Authorized Listings/Partners</h5>

                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Enter text" size='small' InputLabelProps={{ shrink: true }}
                                variant="outlined" value={newTreasury.author_name}
                                onChange={(e) => { setnewTreasury((prev) => ({ ...prev, author_name: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <LocalizationProvider dateAdapter={AdapterDayjs} size='small'>
                                <DatePicker size='small'
                                    label="Date" defaultValue={dayjs(newTreasury?.author_date)}
                                    onChange={(date) => { setnewTreasury((prev) => ({ ...prev, author_date: dateFormat(date) })) }}
                                />
                            </LocalizationProvider>
                        </FormControl>

                        <hr />
                        <h5>Social Media Channels:</h5>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Enter text" size='small' InputLabelProps={{ shrink: true }}
                                variant="outlined" value={newTreasury.social_media_name}
                                onChange={(e) => { setnewTreasury((prev) => ({ ...prev, social_media_name: e.target.value })) }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <LocalizationProvider dateAdapter={AdapterDayjs} size='small'>
                                <DatePicker size='small'
                                    label="Start date" defaultValue={dayjs(newTreasury?.social_start_date)}
                                    onChange={(date) => { setnewTreasury((prev) => ({ ...prev, social_start_date: dateFormat(date) })) }}
                                />
                            </LocalizationProvider>
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <LocalizationProvider dateAdapter={AdapterDayjs} size='small'>
                                <DatePicker size='small'
                                    label="End date" defaultValue={dayjs(newTreasury?.social_end_date)}
                                    onChange={(date) => { setnewTreasury((prev) => ({ ...prev, social_end_date: dateFormat(date) })) }}
                                />
                            </LocalizationProvider>
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <FormControlLabel control={<Switch
                                onChange={(e) => (setnewTreasury((prev) => ({ ...prev, active_stateus: e.target.checked })))}
                            />} label="Active status" />

                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="outlined">
                            <TextField
                                label="Upload Contracts" size='small' type='file'
                                variant="outlined"
                                sx={{ width: 150 }} InputLabelProps={{ shrink: true, required: true }}
                            />
                        </FormControl>
                        {newTreasury.id ? (
                            <Button variant="contained" color='warning' style={{ width: '100%' }}
                                onClick={handalSubmit}>Update</Button>
                        ) : (
                            <Button variant="contained" style={{ width: '100%' }}
                                onClick={handalSubmit}>Submit</Button>
                        )}
                        <Snackbar
                            open={sopen}
                            autoHideDuration={5000}
                            onClose={handleClose}
                            severity="success"
                            message="Treasury successfully added!"
                        />

                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={open}>
                            <CircularProgress color="inherit" />
                        </Backdrop>

                    </form>
                </Card>
            </div>
        </div>
    )
}

export default Treasury