import React, { useState, useEffect } from 'react'
import {
    Card, CardHeader, Grid2, TextField, Breadcrumbs, Typography, FormControl, Button, Divider,
    RadioGroup, FormLabel, FormControlLabel, Radio, InputLabel, Input, InputAdornment,
    Backdrop, IconButton,
    CircularProgress,
    ButtonGroup
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import BasicTable from './BasicTable'
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FaEdit, FaTrash } from 'react-icons/fa'
import { AiOutlineFileAdd } from 'react-icons/ai';

function TreasuryList() {
    const navigate = useNavigate();
    const [usetList, setUserList] = useState([]);
    const fetchUsers = async () => {
        const listUser = await axios.get(`${process.env.REACT_APP_API_URL}/treasury/list`)
        const nnData = listUser.data.result;
        console.log(nnData)
        setUserList(nnData)
        //console.log(listUser.data.result);
    }
    const deleteRow = async (row) => {
        const listUser2 = await axios.post(`${process.env.REACT_APP_API_URL}/treasury/delete`, row)
        const nnData2 = listUser2.data.result;
        setUserList(nnData2)
    }
    useEffect(() => {
        fetchUsers();
    }, [])
    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>List</Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Button variant="contained" color='primary'
                    onClick={() => { navigate('/dashboard/treasury') }}
                >Add new
                    <AiOutlineFileAdd size={20} />
                </Button>
                <Card style={{ padding: '10px' }}>
                    <h4>Treasury list</h4>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Company Name</TableCell>
                                    <TableCell >Full name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Currency type</TableCell>
                                    <TableCell>Blockchain type</TableCell>
                                    <TableCell>Total issuance</TableCell>
                                    <TableCell>Action </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {usetList.map((row, i) => (
                                    <TableRow
                                        key={i}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell > {row.company_name}</TableCell>
                                        <TableCell >{row.first_name} {row.last_name}</TableCell>
                                        <TableCell>{row.email}</TableCell>
                                        <TableCell>{row.author_date}</TableCell>
                                        <TableCell>{row.currency_type}</TableCell>
                                        <TableCell>{row.blockchain_type}</TableCell>
                                        <TableCell>{row.total_issuance}</TableCell>
                                        <TableCell >
                                            <ButtonGroup>
                                                <IconButton color='error' onClick={() => { deleteRow(row) }}>
                                                    <FaTrash />
                                                </IconButton>
                                                <IconButton color='error' onClick={() => { navigate('/dashboard/treasury', {state: row}) }}>
                                                    <FaEdit />
                                                </IconButton>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </div>
        </div>
    )
}

export default TreasuryList