import React, { useEffect, useState } from 'react'

import {
    Drawer, AppBar, Toolbar, IconButton, Input, InputAdornment,
    TextField, FormControl, InputLabel, Box, OutlinedInput, Button, List, ListItem, ListItemText, Typography, CssBaseline,
    Breadcrumbs,
    Card,
    CardHeader,
    Paper
} from '@mui/material';
import { FaHome, FaMedal } from 'react-icons/fa';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

import { AiOutlineSetting, AiOutlineGlobal, AiOutlineHdd, AiOutlineMenu, AiOutlineSearch, AiTwotoneShop, AiOutlineSolution, AiOutlineLaptop, AiOutlineSend, AiFillDatabase } from "react-icons/ai";
//import logo from '../../images/newlogo.png'
import logo from '../images/newlogo.png'

import RightMenu from './comon/RightMenu';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import Home from './Home';
import Rolemaster from './Rolemaster';
import SubscriptionMaster from './SubscriptionMaster';
import Invite from './Invite';
import Individual from './Individual';
import Business from './Business';
import Treasury from './Treasury';
import RolemasterList from './RolemasterList';
import SubscriptionMasterList from './SubscriptionMasterList';
import InviteList from './InviteList';
import TreasuryList from './TreasuryList';




function Dashboard() {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [user, setUser] = useState(null);

    const handleDrawerToggle = () => {
        setOpen(!open);
    };
    useEffect(() => {
        const userData = localStorage.getItem('user');
        console.log(JSON.parse(userData))
        if (userData === null) {
            navigate('/login')
        } else {
            setUser(JSON.parse(userData));
        }
    }, [])
    return (
        <div style={{ display: 'flex' }}>
            {/* backgroundColor="#673ab7" */}
            <Sidebar style={{ height: '700px' }} className="custom-sidebar" toggled={open}>
                <div className='dashboard'>
                    <img src={logo} alt="logo" className='logo' />
                </div>
                {user?.level === 1 ? (
                    <>
                        <Menu>
                            <MenuItem component={<Link to="/dashboard" />} icon={<FaHome />}> Dashboard </MenuItem>
                            <MenuItem component={<Link to="/dashboard/rolemaster" />} icon={<AiOutlineHdd />}> User role master </MenuItem>
                            <MenuItem component={<Link to="/dashboard/subscription-master-list" />} icon={<AiOutlineSetting />}> Subscription Master </MenuItem>
                            <MenuItem component={<Link to="/dashboard/invite-list" />} icon={<AiOutlineSend />} > Invite </MenuItem>
                            <MenuItem component={<Link to="/dashboard/treasury-list" />} icon={<AiFillDatabase />} > Treasury </MenuItem>
                            {/* shareholder registration */}
                            <SubMenu label="Shareholder registration" icon={<AiOutlineLaptop />}>
                                <MenuItem component={<Link to="/dashboard/individual" />} icon={<AiOutlineSolution />} > Individual </MenuItem>
                                <MenuItem component={<Link to="/dashboard/business" />} icon={<AiTwotoneShop />} > Business </MenuItem>
                            </SubMenu>
                            <MenuItem icon={<AiOutlineGlobal />}> Reports & Graphs </MenuItem>
                        </Menu>
                    </>
                ) : (
                    <>
                        <Menu>
                            <MenuItem component={<Link to="/dashboard" />} icon={<FaHome />}> Dashboard </MenuItem>
                            <MenuItem component={<Link to="/dashboard/rolemaster" />} icon={<AiOutlineHdd />}> User role master </MenuItem>
                            <MenuItem component={<Link to="/dashboard/subscription-master" />} icon={<AiOutlineSetting />}> Subscription Master </MenuItem>
                            <MenuItem component={<Link to="/dashboard/invite" />} icon={<AiOutlineSend />} > Invite </MenuItem>
                            <MenuItem component={<Link to="/dashboard/treasury" />} icon={<AiFillDatabase />} > Treasury </MenuItem>
                            {/* shareholder registration */}
                            <SubMenu label="Shareholder registration" icon={<AiOutlineLaptop />}>
                                <MenuItem component={<Link to="/dashboard/individual" />} icon={<AiOutlineSolution />} > Individual </MenuItem>
                                <MenuItem component={<Link to="/dashboard/business" />} icon={<AiTwotoneShop />} > Business </MenuItem>
                            </SubMenu>
                            <MenuItem icon={<AiOutlineGlobal />}> Reports & Graphs </MenuItem>
                        </Menu>
                    </>
                )}


            </Sidebar>
            <div className='right'>
                <div className='in-hdr'>
                    <div>

                        <IconButton size="large" onClick={handleDrawerToggle}>
                            <AiOutlineMenu />
                        </IconButton>
                        <TextField
                            label="Search.."
                            id="outlined-start-adornment"
                            sx={{ m: 1 }}
                            slotProps={{
                                input: {
                                    startAdornment: <InputAdornment position="start">
                                        <AiOutlineSearch />
                                    </InputAdornment>,
                                },
                            }}
                        />
                    </div>

                    <RightMenu />
                </div>
                <Routes>
                    <Route path='/' element={<Home />}></Route>
                    <Route path='/rolemaster' element={<Rolemaster />}></Route>
                    <Route path='/rolemaster-list' element={<RolemasterList />}></Route>
                    <Route path='/subscription-master' element={<SubscriptionMaster />}></Route>
                    <Route path='/subscription-master-list' element={<SubscriptionMasterList />}></Route>
                    <Route path='/invite' element={<Invite />}></Route>
                    <Route path='/invite-list' element={<InviteList />}></Route>
                    <Route path='/individual' element={<Individual />}></Route>
                    <Route path='/business' element={<Business />}></Route>
                    <Route path='/treasury' element={<Treasury />}></Route>
                    <Route path='/treasury-list' element={<TreasuryList />}></Route>
                </Routes>


            </div>
        </div>
    )
}

export default Dashboard